import React from "react";
import styled, { keyframes } from "styled-components";

// Animation keyframes
const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const slideIn = keyframes`
  0% { transform: translate(-50%,50px); opacity: 0; }
  100% { transform: translateY(-50%,0); opacity: 1; }
`;

const textReveal = keyframes`
  0% { opacity: 0; transform: scale(0.8); }
  100% { opacity: 1; transform: scale(1); }
`;

const fadeInText = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

// Styled Components
const HeaderContainer = styled.header`
  background: url("/images/EDU_1504.webp") center center/cover no-repeat;
  background-attachment: fixed; /* Ensures the background does not scroll */
  height: 100vh;
  position: relative;
  text-align: center;
  color: white;
  padding: 0 1.5rem;
  animation: ${fadeIn} 2s ease;
  width: 100%; /* Use 100% to span the container's full width */
  background-color: #ff6f61;
  /* Gradient Overlay */
  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
  }

  @media (max-width: 768px) {
    background: url("/images/EDU_1604.webp") center center/cover no-repeat;
    background-attachment: fixed; /* Ensure it stays fixed on smaller screens as well */
  }
`;

const HeaderContent = styled.div`
  position: absolute; /* Use absolute positioning */
  top: 50%; /* Move 50% from the top */
  left: 50%; /* Move 50% from the left */
  transform: translate(
    -50%,
    -50%
  ); /* Center by adjusting position based on its size */
  z-index: 2;
  color: #ffffff;
  animation: ${slideIn} 1.5s ease;
  width: 90%;
  @media (max-width: 768px) {
    top: 40%; /* Move slightly above center for tablet */
    transform: translate(-50%, -40%);
    padding: 0 1rem;
  }

  @media (max-width: 480px) {
    top: 20%; /* Move further above center for mobile */
    transform: translate(-50%, -10%);
    padding: 0;
  }
`;

const HeaderTitle = styled.h1`
  font-size: 4rem;
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.5);
  animation: ${textReveal} 3s ease;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    font-size: 3.5rem;
  }

  @media (max-width: 480px) {
    font-size: 3rem;
  }
`;

const HeaderDate = styled.p`
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  margin-top: 1rem;
  font-weight: 400;
  opacity: 1;
  animation: ${fadeInText} 2s ease;
  transition: all 0.3s ease;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }

  @media (max-width: 480px) {
    font-size: 1.1rem;
  }
`;

const HeaderTagline = styled.p`
  font-size: 1.2rem;
  font-family: "Poppins", sans-serif;
  margin-top: 0.5rem;
  font-style: italic;
  opacity: 1;
  animation: ${fadeInText} 2.5s ease;
  transition: all 0.3s ease;
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const Header = () => {
  return (
    <HeaderContainer>
      <HeaderContent>
        <HeaderTitle>Kavya & Sandeep</HeaderTitle>
        <HeaderDate>Wedding on 25th December 2024</HeaderDate>
        <HeaderTagline>"Two hearts, one soul, bound by love."</HeaderTagline>
      </HeaderContent>
    </HeaderContainer>
  );
};

export default Header;
