import React, { useRef, useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaCalendarAlt, FaMapMarkerAlt, FaClock } from 'react-icons/fa';

// Animation Keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

// Styled Components
const DetailsSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 20px;
  text-align: center;
  color: #ff6f61;
  width: 100%;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin-bottom: 1rem;
  letter-spacing: 1px;
  color: #ff6f61;
  animation: ${fadeIn} 1s ease-in-out;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.6rem;
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 2rem;

  @media (max-width: 768px) {
    gap: 1.5rem;
  }

  @media (max-width: 480px) {
    gap: 1rem;
  }
`;

export const DecorativeLine = styled.div`
  width: 80px;
  height: 4px;
  background: linear-gradient(to right, #ffafbd, #ffc3a0);
  margin-bottom: 1.5rem;
  animation: ${fadeIn} 2.5s ease-in-out;
  text-align: center;

  @media (max-width: 768px) {
    width: 60px;
  }

  @media (max-width: 480px) {
    width: 50px;
  }
`;

const DetailCard = styled.div`
  background: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  width: 280px;
  padding: 2rem;
  text-align: center;
  transition: transform 0.3s ease, background 0.3s ease;
  backdrop-filter: blur(10px);
  opacity: 0; /* Initially hidden */
  transform: translateY(50px); /* Initially positioned down */
  transition: opacity 0.3s ease, transform 0.3s ease;

  &.visible {
    opacity: 1; /* Fade in */
    transform: translateY(0); /* Reset position */
  }

  h3 {
    font-size: 1.6rem;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    margin: 1.5rem 0;
    color: #ff6f61;
  }

  p {
    font-size: 1.1rem;
    font-family: 'Poppins', sans-serif;
    color: #ff6f61;
  }

  .icon {
    font-size: 2rem;
    color: #ff6f61;
    margin-bottom: 1rem;
  }

  @media (max-width: 768px) {
    width: 220px;
    padding: 1.5rem;
  }

  @media (max-width: 480px) {
    width: 180px;
    padding: 1rem;
    h3 {
      font-size: 1.25rem;
      margin: 0.75rem 0;
    }
    p {
      font-size: 0.8rem;
    }
    .icon {
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
    }
  }
`;

const DetailsSection = () => {
  const [visibleCards, setVisibleCards] = useState(false) 
  const detailCardsRef = useRef()

  useEffect(() => {
    const currentSection = detailCardsRef.current; // Store the current ref value

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setVisibleCards(true); // Set visibility to true when section enters the viewport
        } else {
          setVisibleCards(false); // Optionally, set to false when out of view
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the element is in view
      }
    );

    if (currentSection) {
      observer.observe(currentSection); // Start observing the element
    }

    // Cleanup function
    return () => {
      if (currentSection) {
        observer.unobserve(currentSection); // Remove observer on unmount
      }
    };
  }, []);

  return (
    <DetailsSectionContainer>
      <SectionTitle>Event Details</SectionTitle>
      <DecorativeLine />
      <DetailsWrapper>
        <DetailCard
          ref={detailCardsRef} // Assign ref for the first card
          className={visibleCards?'visible' : ''} // Add 'visible' class when in viewport
        >
          <div className="icon">
            <FaCalendarAlt />
          </div>
          <h3>Wedding Date</h3>
          <p>25th December, 2024</p>
        </DetailCard>

        <DetailCard
          ref={detailCardsRef} // Assign ref for the second card
          className={visibleCards?'visible' : ''} // Add 'visible' class when in viewport
        >
          <div className="icon">
            <FaMapMarkerAlt />
          </div>
          <h3>Venue</h3>
          <p>JNR Kalamandir, Yedthare Byndoor</p>
        </DetailCard>

        <DetailCard
          ref={detailCardsRef} // Assign ref for the third card
          className={visibleCards?'visible' : ''} // Add 'visible' class when in viewport
        >
          <div className="icon">
            <FaClock />
          </div>
          <h3>Muhurtham</h3>
          <p>12.29 P.M. Abhijith Lagnam</p>
        </DetailCard>
      </DetailsWrapper>
    </DetailsSectionContainer>
  );
};

export default DetailsSection;
