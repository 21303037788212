import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Title } from "./CountDown"; 
import { DecorativeLine } from "./DetailsSection"; 

// Styled component for Map Section
const MapSection = styled.section`
  width: 100%;
  padding: 3rem 1rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 0.5rem;
  opacity: 0; /* Start with hidden */
  transition: opacity 0.5s ease-in-out; /* Smooth transition */
  &.visible {
    opacity: 1; /* Fade in when visible */
  }
`;

// Styled component for Map Container
const MapContainer = styled.div`
  width: 100%;
  max-width: 800px;
  height: 400px;

  iframe {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 10px;
  }
`;

const Map = () => {
  const mapSectionRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const currentSection = mapSectionRef.current; // Store the current ref value

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true); // Set visibility to true when section enters the viewport
        } else {
          setIsVisible(false); // Optionally, set to false when out of view
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the element is in view
      }
    );

    if (currentSection) {
      observer.observe(currentSection); // Start observing the element
    }

    // Cleanup function
    return () => {
      if (currentSection) {
        observer.unobserve(currentSection); // Remove observer on unmount
      }
    };
  }, []);

  return (
    <MapSection ref={mapSectionRef} className={isVisible ? "visible" : ""}>
      <Title>Our Wedding Venue</Title>
      <DecorativeLine />
      <MapContainer>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3873.6822795492058!2d74.62780617485795!3d13.858100386545033!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bbc60e66c840587%3A0xbcbb22fade53cb51!2sJNR%20Hall%20Byndoor!5e0!3m2!1sen!2sin!4v1733207807851!5m2!1sen!2sin"
          title="Wedding Venue"
          allowFullScreen={true}
          loading="lazy"
        ></iframe>
      </MapContainer>
    </MapSection>
  );
};

export default Map;
