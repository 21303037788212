import React from 'react';
import styled from 'styled-components';

// Styled Components
const FooterContainer = styled.footer`
  background: #222;
  color: #fff;
  text-align: center;
  padding: 2rem 1rem;
  font-family: 'Poppins', sans-serif;
  width:100%;
`;

const FooterText = styled.p`
  margin: 1rem 0;
  font-size: 1.2rem;
  line-height: 1.5;
`;

const FooterBottomText = styled.p`
  margin: 1rem 0;
  font-size: 0.8rem;
  line-height: 1.5;
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterText>Thank you for celebrating with us!</FooterText>
      <FooterText>We can't wait to see you at the wedding.</FooterText>

      <FooterBottomText>Made with ❤️</FooterBottomText>
    </FooterContainer>
  );
};

export default Footer;
