import React, {useRef, useEffect } from 'react';
import styled from 'styled-components';
import Countdown from 'react-countdown';
import { DecorativeLine } from './DetailsSection';

const CountdownSectionContainer = styled.section`
  background-color: #fff;
  padding: 60px 20px;
  text-align: center;
  border-top: 2px solid #ff6f61;
  border-bottom: 2px solid #ff6f61;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.h2`
  font-size: 2.5rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin-bottom: 20px;
  color: #ff6f61;

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.8rem;
  }
`;

const CountdownTimer = styled.div`
  font-size: 3rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  display: flex;
  justify-content: center;
  gap: 20px;
  color: #ff6f61;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 10px;
  }

  @media (max-width: 480px) {
    gap: 5px;
  }
`;

const CountdownItem = styled.div`
  text-align: center;
  padding: 20px;
  background-color: #ff6f61;
  color: #fff;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  flex: 1;
  min-width: 100px;
  opacity: 0;
  transform: translateY(50px);
  transition: all 0.3s ease-in-out;

  &.in-view {
    opacity: 1;
    transform: translateY(0);
  }

  h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      font-size: 1.2rem;
    }

    @media (max-width: 480px) {
      font-size: 1rem;
      margin-bottom: 4px;
    }
  }

  p {
    font-size: 2rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
    }

    @media (max-width: 480px) {
      font-size: 1.2rem;
    }
  }
     
   @media (max-width: 480px) {
      font-size: 1rem;
      padding:10px;
      min-width:80px;    }
`;

const CountDownContentWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CountdownSection = () => {
  
  const countdownRef = useRef(null);

  // Intersection Observer setup
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('in-view');
          } else {
            entry.target.classList.remove('in-view');
          }
        });
      },
      { threshold: 0.5 }
    );

    // Observe all countdown items
    if (countdownRef.current) {
      const items = countdownRef.current.querySelectorAll('.countdown-item');
      items.forEach((item) => observer.observe(item));
    }

    return () => observer.disconnect();
  }, []);

  // Date of the wedding (change this to your wedding date)
  const weddingDate = new Date('2024-12-25T16:00:00');

  // Countdown renderer to format the time
  const countdownRenderer = ({ days, hours, minutes, seconds }) => {
    return (
      <CountdownTimer ref={countdownRef}>
        <CountdownItem className="countdown-item">
          <h3>Days</h3>
          <p>{days}</p>
        </CountdownItem>
        <CountdownItem className="countdown-item">
          <h3>Hours</h3>
          <p>{hours}</p>
        </CountdownItem>
        <CountdownItem className="countdown-item">
          <h3>Minutes</h3>
          <p>{minutes}</p>
        </CountdownItem>
        <CountdownItem className="countdown-item">
          <h3>Seconds</h3>
          <p>{seconds}</p>
        </CountdownItem>
      </CountdownTimer>
    );
  };

  return (
    <CountdownSectionContainer>
      <CountDownContentWrapper>
        <Title>Countdown to the Wedding</Title>
        <DecorativeLine />
      </CountDownContentWrapper>
      <Countdown date={weddingDate} renderer={countdownRenderer} />
    </CountdownSectionContainer>
  );
};

export default CountdownSection;
