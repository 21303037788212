import React from 'react';
import Header from './components/Header';
import WelcomeSection from './components/WelcomeSection';
import DetailsSection from './components/DetailsSection';
import Map from './components/Map';
import CountdownSection from './components/CountDown';
import Gallery from './components/Gallery';
import Footer from './components/Footer';
import { createGlobalStyle } from 'styled-components';
import './App.css'
const GlobalStyle = createGlobalStyle`
  body {
    overflow-x: hidden;
  }
`;




function App() {
    
  return (
    <>
      <GlobalStyle />
      <Header />
      <WelcomeSection />
      <DetailsSection />
      <CountdownSection />
      <Map /> 
      <Gallery />
      <Footer />
      </>
    
  );
};

export default App;
