import React, { useState, useRef } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import Masonry from "react-masonry-css";

// Styled Components
const GallerySection = styled.section`
  padding: 4rem 1rem;
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const GalleryTitle = styled.div`
  font-size: 2.5rem;
  font-family: "Poppins", sans-serif;
  margin-bottom: 2rem;
  color: #333;
  position: relative;
  display: inline-block;
  animation: fadeIn 1.5s ease;

  &::after {
    content: "";
    position: absolute;
    bottom: -1rem;
    left: 50%; /* Start at the center */
    transform: translateX(-50%); /* Adjust to center the line */
    width: 30%; /* Adjust width as needed */
    height: 5px;
    background: linear-gradient(to right, #ff4081, #ffa726);
    border-radius: 10px;
  }

  @media (max-width: 768px) {
    font-size: 1.7rem;
  }

  @media (max-width: 480px) {
    font-size: 1.3rem;
  }
`;

const StyledSlider = styled(Slider)`
  width: 90%;
  margin-bottom: 3rem;

  .slick-slide img {
    border-radius: 15px;
    width: 100%;
    height: auto;
  }

  .slick-dots li button:before {
    font-size: 12px;
    color: #333;
  }

  .slick-dots li.slick-active button:before {
    color: #ff4081;
  }

  .slick-prev,
  .slick-next {
    z-index: 10;
    color: #ff4081;
  }

  .slick-prev:before,
  .slick-next:before {
    color: #ff4081;
  }
`;

const MasonryGrid = styled(Masonry)`
  display: flex;
  margin-top: 2rem;
  width: 90%;

  & > div {
    margin: 0.5rem;
  }

  img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    transition: transform 0.3s ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.05);
    }
    @media (max-width: 480px) {
      &:hover {
        transform: none;
      }
    }
  }
`;

const Pagination = styled.div`
  margin: 2rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;

  button {
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #ff4081;
    color: white;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #ffa726;
    }

    &:disabled {
      background-color: #ccc;
      cursor: not-allowed;
    }
  }
`;

const Gallery = () => {
  const galleryRef = useRef(null);
  const sliderImages = [
    "images/EDU_1.webp",
    "images/EDU_2.webp",
    "images/EDU_3.webp",
    "images/EDU_4.webp",
    "images/EDU_5.webp",
    "images/EDU_6.webp",
    "images/EDU_7.webp",
    "images/EDU_8.webp",
  ];

  const tileImages = Array.from(
    { length: 39 },
    (_, index) => `images/pre-wedding-album/EDU_${index + 1}.webp`
  );
  const itemsPerPage = 20; // Number of images per page
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(tileImages.length / itemsPerPage);

  const handleNext = () => {
    setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    galleryRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handlePrev = () => {
    setCurrentPage((prev) => Math.max(prev - 1, 1));
    galleryRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const currentImages = tileImages.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const breakpointColumns = {
    default: 4,
    1024: 3,
    768: 2,
    480: 2,
  };

  return (
    <GallerySection ref={galleryRef}>
      <GalleryTitle>✨ Pre-Wedding Bliss ✨</GalleryTitle>
      <StyledSlider {...sliderSettings}>
        {sliderImages.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Slider ${index + 1}`} />
          </div>
        ))}
      </StyledSlider>

      <GalleryTitle>Captured Moments 🖼️</GalleryTitle>
      <MasonryGrid
        breakpointCols={breakpointColumns}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {currentImages.map((image, index) => (
          <div key={index}>
            <img src={image} alt={`Memory ${index + 1}`} loading="lazy"/>
          </div>
        ))}
      </MasonryGrid>

      <Pagination>
        <button onClick={handlePrev} disabled={currentPage === 1}>
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button onClick={handleNext} disabled={currentPage === totalPages}>
          Next
        </button>
      </Pagination>
    </GallerySection>
  );
};

export default Gallery;
