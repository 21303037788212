import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes, css } from 'styled-components';

// Animation Keyframes
const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
`;

const WelcomeSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 1.5rem;
  background: linear-gradient(to bottom, #ff9a9e, #fad0c4);
  color: #333;
  text-align: center;
  width: 100%;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
  }
`;

const animatedStyles = css`
  opacity: 1;
  transform: translateY(0);
  animation: ${fadeIn} 1.5s ease-in-out;
`;

const WelcomeText = styled.h2`
  font-size: 2.5rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #fff;
  opacity: 0; /* Initially hidden */
  transform: translateY(50px); /* Off-screen */
  ${({ isVisible }) => isVisible && animatedStyles}

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 480px) {
    font-size: 1.6rem;
  }
`;

const SubText = styled.p`
  font-size: 1.2rem;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  max-width: 600px;
  line-height: 1.6;
  color: #fff;
  opacity: 0; /* Initially hidden */
  transform: translateY(50px); /* Off-screen */
  ${({ isVisible }) => isVisible && animatedStyles}

  @media (max-width: 768px) {
    font-size: 1rem;
    max-width: 500px;
  }

  @media (max-width: 480px) {
    font-size: 0.9rem;
    max-width: 400px;
  }
`;

const DecorativeLine = styled.div`
  width: 80px;
  height: 4px;
  background-color: #fff;
  margin-bottom: 1.5em;
  opacity: 0; /* Initially hidden */
  transform: translateY(50px); /* Off-screen */
  ${({ isVisible }) => isVisible && animatedStyles}

  @media (max-width: 768px) {
    width: 60px;
  }

  @media (max-width: 480px) {
    width: 50px;
  }
`;

const ScrollHint = styled.div`
  margin-top: 2rem;
  color: #fff;
  font-size: 1rem;
  font-family: 'Poppins', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;

  &::after {
    content: '';
    width: 20px;
    height: 20px;
    border: 2px solid #fff;
    border-radius: 50%;
    animation: ${bounce} 2s infinite;
  }

  @media (max-width: 768px) {
    font-size: 0.9rem;
  }

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const WelcomeSection = () => {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const currentSection = sectionRef.current; // Store the current ref value

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsVisible(true); // Set visibility to true when section enters the viewport
        } else {
          setIsVisible(false); // Optionally, set to false when out of view
        }
      },
      {
        threshold: 0.5, // Trigger when 50% of the element is in view
      }
    );

    if (currentSection) {
      observer.observe(currentSection); // Start observing the element
    }

    // Cleanup function
    return () => {
      if (currentSection) {
        observer.unobserve(currentSection); // Remove observer on unmount
      }
    };
  }, []);

  return (
    <WelcomeSectionContainer ref={sectionRef}>
      <WelcomeText isVisible={isVisible}>Welcome to Our Special Day</WelcomeText>
      <DecorativeLine isVisible={isVisible} />
      <SubText isVisible={isVisible}>
        Join us as we celebrate the union of Kavya & Sandeep. We’re so excited to share this beautiful moment with our family and friends!
      </SubText>
      <ScrollHint>Scroll Down to Explore</ScrollHint>
    </WelcomeSectionContainer>
  );
};

export default WelcomeSection;
